<template>
<v-card>
  <v-card-title>
    <span class="headline">#{{ confession.id }}: {{ confession.title === '' ? 'A confession ...' : confession.title }}</span>
  </v-card-title>
  <v-card-text class="text-center font-weight-bold">
    <v-row>
      <v-col cols="12">
        <h3
          v-if="this.comments.length === 0"
        >
          Care to add the first comment?
        </h3>

        <v-card
          v-else
          class="mx-auto indigo accent-1 mb-1 text-left"
          v-for="comment in this.comments" :key="comment.id"
        >
          <v-card-text class="ma-0 ml-2 pa-0">
            <div class="text--primary font-weight-bold">
              {{ comment.author === '' ? 'Anonymous Individual' : comment.author }}: <br />
              {{ comment.text }}
            </div>
          </v-card-text>
          <v-card-actions class="ma-0 ml-2 pa-0">
            <v-btn
              icon
              color="red"
              :disabled="likesDisabled"
              @click="like(comment)"
            >
              <v-icon class="mr-1">
                mdi-heart
              </v-icon>
            </v-btn>
            <span class="subheading mr-2">{{ comment.likes }}</span>
            <span class="mr-1">·</span>
            <v-btn
              icon
              color="red"
              :disabled="likesDisabled"
              @click="dislike(comment)"
            >
              <v-icon class="mr-1">
                mdi-heart-broken
              </v-icon>
            </v-btn>
            <span class="subheading">{{ comment.dislikes }}</span>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-card-text>

  <v-card-actions>
    <v-dialog
      v-model="showCommentDialog"
      persistent
      transition="dialog-top-transition"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          x-large
        >
          Add Comment
        </v-btn>
      </template>
      <v-card>
        <v-toolbar
          class="headline"
          color="primary"
          dark
        >
          Add Comment
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="newComment.author"
                  label="Author"
                  persistent-hint
                  hint="Leave empty for annonymous"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
              >
                <v-textarea
                  v-model="newComment.text"
                  filled
                  shaped
                  rows="8"
                  row-height="30"
                  :label="inputOverflow ? 'Comment too long' : 'Comment goes here 👇'"
                  :counter="maxCommentTextLen"
                  :error="inputOverflow"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
            text
            :disabled="newCommentTextEmpty || inputOverflow"
            @click="sendComment"
          >Send 📨</v-btn>
          <v-btn
            text
            @click="showCommentDialog = false"
          >Abort 🛑</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-spacer></v-spacer>
    <v-btn class="float-right" color="indigo accent-1" text @click="close">Close</v-btn>
  </v-card-actions>

  <v-snackbar
    v-model="snackbar"
    :color="snackbarColor"
    :timeout="snackbarTimeout"
  >
    {{ snackbarMessage }}
    <template v-slot:action="{ attrs }">
      <v-btn
        text
        v-bind="attrs"
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </template>
  </v-snackbar>
</v-card>
</template>

<script>
import axios from 'axios'
const qs = require('querystring')

export default {
  name: 'Comments',
  props: {
    confession: Object
  },
  data: () => ({
    comments: [],
    showCommentDialog: false,
    newComment: {},
    maxCommentTextLen: 255,
    snackbarColor: 'blue',
    snackbar: false,
    snackbarMessage: '',
    snackbarTimeout: 6000,
    likesDisabled: false
  }),
  computed: {
    inputOverflow() {
      if (!this.newComment || !this.newComment.text) {
        return false
      }
      return this.newComment.text.length >= this.maxCommentTextLen
    },
    newCommentTextEmpty() {
      return this.newComment.text === undefined || this.newComment.text.length === 0
    }
  },
  methods: {
    snackbarOk(message) {
      this.snackbarMessage = message
      this.snackbarColor = 'success'
      this.snackbar = true
    },
    snackbarFuckup(message) {
      this.snackbarMessage = message
      this.snackbarColor = 'red'
      this.snackbar = true
    },
    close () {
      this.$emit('closed')
    },
    like (comment) {
      this.sendLike('love', comment)
    },
    dislike (comment) {
      this.sendLike('hate', comment)
    },
    sendLike (type, comment) {
      this.likesDisabled = true

      const requestBody = {
        id: comment.id,
        type: type
      }

      const vm = this
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + '/c/comments/likes',
          qs.stringify(requestBody), {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
        .then((response) => {
          if (response === null || response.data === null) {
            console.error('send comment like/dislike - received null response / data')
            return
          }
          if (response.data !== 'ok') {
            console.error(response.data)
            return
          }
          if (type === 'love') {
            comment.likes++
          } else {
            comment.dislikes++
          }

          setTimeout(function () {
            vm.likesDisabled = false
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    sendComment () {
      if (this.newComment.text === undefined || this.newComment.text === '') {
        console.error('emtpy comment text')
        return
      }

      const requestBody = {
        confessionId: this.confession.id,
        author: this.newComment.author,
        text: this.newComment.text
      }

      const vm = this
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + '/c/comments',
          qs.stringify(requestBody), {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
        .then(function (response) {
          if (response.data === null || !response.data.startsWith('added:')) {
            vm.snackbarFuckup('Received unexpected response from the server')
            console.error(response)
            return
          }

          vm.confession.commentsCount++
          vm.snackbarOk('Sent successfully!')

          let author = !requestBody.author ? 'Anonymous Individual' : requestBody.author
          vm.newComment.title = ''
          vm.newComment.text = ''

          const newCommentId = response.data.split(':')[1]
          vm.comments.unshift({
            id: newCommentId,
            confession_id: vm.confession.id,
            author: author,
            text: requestBody.text,
            likes: 0,
            dislikes: 0,
            timestamp: new Date()
          })
        })
        .catch(function (error) {
          vm.snackbarFuckup('Received an error response from the server')
          console.log(error)
        })
        .finally(() => {
          vm.showCommentDialog = false
        })
    }
  },
  mounted: function () {
    const vm = this
    axios
      .get(process.env.VUE_APP_API_ENDPOINT + `/c/comments/${this.confession.id}`, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then((response) => {
        if (response === null || response.data === null) {
          console.error('get all confessions - received null response / data')
          return
        }
        vm.comments = response.data
      })
      .catch((error) => {
        console.log(error)
      })
  }
}
</script>

<style lang="scss" scoped>

</style>