<template>
  <v-hover 
    close-delay="100"
  >
    <v-card
      slot-scope="{ hover }"
      :class="`mx-auto indigo ${hover ? 'accent-1' : 'lighten-2'}`"
      max-width="950"
    >
      <v-card-title>
        <v-icon
          large
          left
        >
          mdi-newspaper
        </v-icon>
        <span class="title font-weight-light">#{{ confession.id }}: {{ confession.title === '' ? 'A confession ...' : confession.title }}</span>
        <v-spacer></v-spacer>
        <span class="title pa-2 indigo lighten-2 font-weight-light rounded-pill">{{ confession.timestamp | moment('LLL') }}</span>
      </v-card-title>

      <v-card-text class="headline font-weight-bold">
        "{{ confession.text }}"
      </v-card-text>

      <v-card-actions>
        <v-list-item class="grow">
          <v-icon
            large
            left
          >
            mdi-account
          </v-icon>

          <v-list-item-content class="text-left">
            <v-list-item-title>{{ confession.author === '' ? 'Anonymous Individual' : confession.author }}</v-list-item-title>
          </v-list-item-content>

          <v-row
            align="center"
            justify="end"
          >
            <v-btn
              icon
            >
              <v-dialog v-model="showCommentsDialog" width="70%">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on">
                    <v-icon class="mr-1">
                      mdi-comment
                    </v-icon>
                  </v-btn>
                </template>
                <Comments 
                  :confession="confession" 
                  v-on:closed="showCommentsDialog = false"
                />
              </v-dialog>
            </v-btn>
            <span class="subheading mr-2">{{ confession.commentsCount }}</span>
            <v-btn
              icon
              color="red"
              :disabled="likesDisabled"
              @click="like(confession)"
            >
              <v-icon class="mr-1">
                mdi-heart
              </v-icon>
            </v-btn>
            <span class="subheading mr-2">{{ confession.likes }}</span>
            <span class="mr-1">·</span>
            <v-btn
              icon
              color="red"
              :disabled="likesDisabled"
              @click="dislike(confession)"
            >
              <v-icon class="mr-1">
                mdi-heart-broken
              </v-icon>
            </v-btn>
            <span class="subheading">{{ confession.dislikes }}</span>
          </v-row>
        </v-list-item>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import axios from 'axios'
const qs = require('querystring')
import Comments from './Comments.vue';

export default {
  name: 'Confession',
  components: {
    Comments
  },
  props: {
    confession: Object
  },
  data: () => ({
    likesDisabled: false,
    showCommentsDialog: false
  }),
  methods: {
    sendLike (type, confession) {
      this.likesDisabled = true

      const requestBody = {
        id: confession.id,
        type: type
      }

      const vm = this
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + '/c/likes',
          qs.stringify(requestBody), {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
        .then((response) => {
          if (response === null || response.data === null) {
            console.error('send confession like/dislike - received null response / data')
            return
          }
          if (response.data !== 'ok') {
            console.error(response.data)
            return
          }
          if (type === 'love') {
            confession.likes++
          } else {
            confession.dislikes++
          }

          setTimeout(function () {
            vm.likesDisabled = false
          }, 1000);
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    like (confession) {
      this.sendLike('love', confession)
    },
    dislike (confession) {
      this.sendLike('hate', confession)
    }
  }
}
</script>
