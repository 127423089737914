<template>
  <v-app>
    <v-main style="margin-bottom: 175px;">
      <ConfessionsMain/>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
import ConfessionsMain from './components/ConfessionsMain';
import Footer from './components/Footer';

export default {
  name: 'App',
  metaInfo: {
    title: 'Ultimate Honesty [confessions and secrets, anonymous sharing]',
    htmlAttrs: {
      lang: 'en-US'
    },
    meta: [
      { charset: 'utf-8' },
      { name: 'description', content: 'Open yourself incognito to the public. Share your confessions with others, and have fun reading the same from them.' },
    ]
  },
  components: {
    ConfessionsMain,
    Footer
  }
};
</script>
