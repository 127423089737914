<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/inspiration.png')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="ma-0 pb-0">
        <h1 class="display-2 font-weight-bold mb-3">
          Our little confessions and secrets
        </h1>

        <p class="display-1 ma-0 font-weight-regular">
          Here you can think out loud anonymously.
        </p>
        <p class="subheading ma-0 font-weight-regular">
          <span class="font-weight-bold">True incognito</span> - <span class="red--text">no</span> logins, <span class="red--text">no</span> account, <span class="red--text">no</span> email addresses, <span class="red--text">no</span> cookies, <span class="red--text">nothing</span>, just pure <span class="font-weight-bold">honesty</span>.
          Let's share it and have fun!
        </p>

        <div v-if="!confessionsLoading">
          <h3
            v-if="$root.confessions.length === 0"
            class="display-1 font-weight-bold mt-12"
          >
            Want to be the first one to write a secret or a confession? 😉
          </h3>
          <h3
            v-if="$root.confessions.length === 0"
            class="display-1 font-weight-bold mb-3"
          >
            Hit that button down bellow
          </h3>
          <h3
            v-if="$root.confessions.length === 0"
            class="display-2 font-weight-bold mb-3"
          >
            👇
          </h3>
        </div>

        <v-row
          v-if="$root.confessions.length > 0 && !confessionsLoading"
          class="mt-5"
          align="center"
        >
          <v-col cols="8"></v-col>
          <v-col cols="3" class="ma-0 pa-0">
            <v-select
              filled
              v-model="sortBy"
              label="Sort By"
              :items="sortByFields"
              :append-icon="sortByIcon"
              @change="sortyByChange"
            ></v-select>
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>
      </v-col>

      <v-col
        v-for="confession in $root.confessions" :key="confession.id"
        class="mb-5"
        cols="12"
      >
        <Confession
          v-observe-visibility="{
            callback: (isVisible, entry) => visibilityChanged(isVisible, entry, confession.id),
            once: true,
          }"
          :confession="confession"
        />
      </v-col>

      <v-col
        v-if="confessionsLoading"
        class="mx-auto mt-0"
        cols="12"
      >
        <v-skeleton-loader
          v-for="i in [0, 1, 2]" :key="i"
          :color="`indigo ${theme.isDark ? 'darken-2' : 'lighten-2'}`"
          dark
          class="mx-auto pa-0 mt-10"
          max-width="950"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import Confession from './Confession.vue';

export default {
  name: 'ConfessionsMain',
  components: {
    Confession,
  },
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  data() {
    const sortByFieldsArray = [
      {
        text: 'Timestamp',
        value: 'timestamp'
      },
      {
        text: 'Likes',
        value: 'likes'
      },
      {
        text: 'Dislikes',
        value: 'dislikes'
      }
    ]

    return {
      confessionsLoading: true,
      lastConfessionId: -1,
      sortByFields: sortByFieldsArray,
      sortBy: sortByFieldsArray[0],
      sortByIcon: 'mdi-clock',
      sortByIcons: {
        timestamp: 'mdi-clock',
        likes: 'mdi-heart',
        dislikes: 'mdi-heart-broken'
      }
    }
  },
  methods: {
    sortyByChange: function() {
      this.sortByIcon = this.sortByIcons[this.sortBy]
      this.$root.confessions = []
      this.getConfessions(-1)
    },
    visibilityChanged (isVisible, entry, confessionId) {
      if (!isVisible) {
        return
      }
      if (this.lastConfessionId < 0) {
        return
      }
      if (this.lastConfessionId !== confessionId) {
        return
      }

      console.log(`will try to load new confessions after #${confessionId} / ${this.$root.confessions.length} ...`)

      // load additional confessions
      this.getConfessions()
    },
    getConfessions() {
      let sortField = this.sortBy
      if (sortField !== 'timestamp' && sortField !== 'likes' && sortField !== 'dislikes') {
        sortField = 'timestamp'
      }

      let offset = 0
      if (this.$root.confessions) {
        offset = this.$root.confessions.length
      }

      let path = `/c/by/${sortField}/offset/${offset}`
      // console.warn(path)

      this.confessionsLoading = true

      const vm = this
      axios
        .get(process.env.VUE_APP_API_ENDPOINT + path, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then((response) => {
          if (response === null || response.data === null) {
            console.error('get all confessions - received null response / data')
            return
          }

          response.data.forEach(c => {
            vm.$root.confessions.push(c)
          });

          const c = vm.$root.confessions
          if (c) {
            vm.lastConfessionId = c[c.length - 1].id
          } else {
            vm.lastConfessionId = -1
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          vm.confessionsLoading = false
        })
    }
  },
  mounted: function () {
    this.getConfessions()
  }
}
</script>
