import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueMeta from 'vue-meta';
import VueObserveVisibility from 'vue-observe-visibility'

Vue.config.productionTip = false

Vue.use(VueMeta);
Vue.use(VueObserveVisibility)

new Vue({
  vuetify,
  render: h => h(App),
  data: {
    confessions: []
  }
}).$mount('#app')
