<template>
  <v-card>
    <v-footer
      fixed
      padless
    >
      <v-card
        flat
        tile
        width="100%"
        class="indigo accent-1 text-center"
      >
        <v-card-text>
        <v-dialog
          v-model="showConfessionDialog"
          persistent
          transition="dialog-top-transition"
          max-width="600"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              class="mx-4 indigo accent-1"
              elevation="15"
              icon
              x-large
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-toolbar
              class="headline"
              color="primary"
              dark
            >
              What's on your mind today? 🤔
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="9"
                    md="7"
                  >
                    <v-text-field
                      v-model="newConfession.title"
                      autocomplete="new-password"
                      label="Title"
                      persistent-hint
                      hint="You can leave it empty"
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="7"
                    md="5"
                  >
                    <v-text-field
                      v-model="newConfession.author"
                      label="Author"
                      persistent-hint
                      hint="Leave empty for annonymous"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                  >
                    <v-textarea
                      v-model="newConfession.text"
                      filled
                      :label="inputOverflow ? 'Confession text too long' : 'Confession goes here 👇'"
                      rows="8"
                      :counter="maxConfessionTextLen"
                      :error="inputOverflow"
                      row-height="30"
                      shaped
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                text
                :disabled="newConfessionTextEmpty || inputOverflow"
                @click="sendConfession"
              >Send 📨</v-btn>
              <v-btn
                text
                @click="showConfessionDialog = false"
              >Abort 🛑</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-text class="indigo accent-1 pa-1 white--text">
          <h3>☝️ Share an anonymous confession/secret here ☝️</h3>
          <!-- <div>
            Icons made by <a href="https://www.flaticon.com/authors/photo3idea-studio" title="photo3idea_studio">photo3idea_studio</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
          </div> -->
        </v-card-text>

        <v-snackbar
          v-model="snackbar"
          :color="snackbarColor"
          :timeout="snackbarTimeout"
        >
          {{ snackbarMessage }}
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-footer>
  </v-card>
</template>

<script>
import axios from 'axios'
const qs = require('querystring')

export default {
  name: 'Footer',
  data: () => ({
    showConfessionDialog: false,
    newConfession: {},
    snackbarColor: 'blue',
    snackbar: false,
    snackbarMessage: '',
    snackbarTimeout: 6000,
    maxConfessionTextLen: 2000
  }),
  computed: {
    inputOverflow() {
      if (!this.newConfession || !this.newConfession.text) {
        return false
      }
      return this.newConfession.text.length >= this.maxConfessionTextLen
    },
    newConfessionTextEmpty() {
      return this.newConfession.text === undefined || this.newConfession.text.length === 0
    }
  },
  methods: {
    snackbarOk(message) {
      this.snackbarMessage = message
      this.snackbarColor = 'success'
      this.snackbar = true
    },
    snackbarFuckup(message) {
      this.snackbarMessage = message
      this.snackbarColor = 'red'
      this.snackbar = true
    },
    sendConfession () {
      if (this.newConfession.text === undefined || this.newConfession.text === '') {
        console.error('emtpy confession text')
        return
      }

      const requestBody = {
        title: this.newConfession.title,
        author: this.newConfession.author,
        text: this.newConfession.text
      }

      const vm = this
      axios
        .post(
          process.env.VUE_APP_API_ENDPOINT + '/c/new',
          qs.stringify(requestBody), {
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
        .then(function (response) {
          if (response.data === null || !response.data.startsWith('added:')) {
            vm.snackbarFuckup('Received unexpected response from the server')
            console.error(response)
            return
          }

          vm.snackbarOk('Sent successfully!')

          let title = !requestBody.title ? 'A confession ...' : requestBody.title
          let author = !requestBody.author ? 'Anonymous Individual' : requestBody.author
          vm.newConfession.title = ''
          vm.newConfession.text = ''

          const newConfessionId = response.data.split(':')[1]
          vm.$root.confessions.unshift({
            id: newConfessionId,
            title: title,
            author: author,
            text: requestBody.text,
            likes: 0,
            dislikes: 0,
            timestamp: new Date(),
            commentsCount: 0
          })
        })
        .catch(function (error) {
          vm.snackbarFuckup('Received an error response from the server')
          console.log(error)
        })
        .finally(() => {
          this.showConfessionDialog = false
        })
    }
  }
}
</script>
